<template>
  <div class="page-box">
    <div class="wrap">
      <div class="add-btn-box">
        <a-button type="primary" @click="showAddMoadl">
          <a-icon type="plus-circle" />
          <span>添加员工</span>
        </a-button>
      </div>
      <div class="table-box">
        <a-table
          :rowKey="
            (item, index) => {
              return index;
            }
          "
          :loading="dataLoading"
          :columns="columns"
          :data-source="list"
          :bordered="true"
          :pagination="{
            hideOnSinglePage: true,
            current: page.current,
            total: page.total,
          }"
          @change="listChange"
        >
          <template slot="operation" slot-scope="index, item">
            <div class="control-box">
              <a-button size="small" type="primary" @click="toEdit(item)"
                >编辑</a-button
              >
              <a-popconfirm
                title="确认删除该员工"
                ok-text="是"
                cancel-text="否"
                :loading="deleteIng"
                @confirm="deleteItem(item)"
              >
                <a-button size="small" type="danger">删除</a-button>
              </a-popconfirm>
              <a-button
                size="small"
                type="primary"
                @click="showEditPassword(item)"
                >修改密码</a-button
              >
            </div>
          </template>
        </a-table>
      </div>
    </div>

    <a-modal
      ok-text="提交"
      cancel-text="取消"
      :title="(user_id ? '修改' : '创建') + '员工'"
      :visible="visibleInfo"
      @ok="submitInfo"
      :confirmLoading="confirmLoading"
      @cancel="handleCancel"
    >
      <a-form-model
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 21 }"
        ref="userForm"
        :model="userInfo"
        :rules="userRules"
      >
        <a-form-model-item prop="name" label="员工">
          <a-input v-model="userInfo.name"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="name" label="账号">
          <a-input v-model="userInfo.user_name"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="name" label="密码" v-if="!user_id">
          <a-input v-model="userInfo.password"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="name" label="角色">
          <a-checkbox-group v-model="userInfo.role_list">
            <a-checkbox
              v-for="(item, index) in roleList"
              :key="item.role_id"
              :value="item.role_id"
              >{{ item.name }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      ok-text="提交"
      cancel-text="取消"
      title="修改密码"
      :visible="visiblePassword"
      @ok="submitPassword"
      :confirmLoading="confirmPassLoading"
      @cancel="cancelPassword"
    >
      <a-form-model
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        ref="passForm"
        :model="passInfo"
        :rules="passRules"
      >
        <a-form-model-item prop="password" label="密码">
          <a-input v-model="passInfo.password"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    let columns = [
      {
        title: "员工",
        key: "name",
        dataIndex: "name",
      },
      {
        title: "账号",
        key: "user_name",
        dataIndex: "user_name",
      },
      {
        title: "创建日期",
        key: "create_at_str",
        dataIndex: "create_at_str",
      },
      {
        title: "操作",
        dataIndex: "operation",
        scopedSlots: { customRender: "operation" },
        width: "221px",
      },
    ];
    let rulesValidator = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请选择角色权限"));
      } else {
        callback();
      }
    };
    let reValidator = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.passInfo.newPassword) {
        callback(new Error("两次密码输入不一致"));
      } else {
        callback();
      }
    };
    return {
      columns,
      dataLoading: false,
      list: [],
      visibleInfo: false,
      visiblePassword: false,
      page: {
        current: 1,
        total: 0,
      },
      passInfo: {
        user_id:'',
        password: "",
      },
      userInfo: {
        name: "",
        user_name: "",
        password: "",
        role_list: [],
      },
      userRules: {
        name: [
          {
            required: true,
            message: "填写员工名称",
            trigger: "blur",
          },
        ],
        user_name: [
          {
            required: true,
            message: "填写账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        role_id: [
          {
            validator: rulesValidator,
            required: true,
            message: "请至少选择一个角色",
            trigger: "change",
          },
        ],
      },
      passRules: {
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      user_id: "",
      roleList: [],
      confirmLoading: false,
      confirmPassLoading: false,
      deleteIng: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
    this.getJueseList();
  },
  methods: {
    showAddMoadl() {
      this.visibleInfo = true;
    },
    getJueseList() {
      let t = this;
      t.$post("roleList", { page: 1, limit: 9999 }).then((res) => {
        let { code, data, msg } = res;
        if (code == 0) {
          t.roleList = data.list;
        }
      });
    },
    getList() {
      let t = this;
      t.dataLoading = true;
      t.$post("userList", { page: t.page.current })
        .then((res) => {
          let { code, data, msg } = res;
          t.dataLoading = false;
          if (code == 0) {
            t.list = data.list;
            t.page.total = data.total;
          }
        })
        .catch((err) => {
          console.log(123123123);
          t.dataLoading = false;
        });
    },
    listChange(e) {
      this.page.current = e.current;
      this.getList();
    },
    itemChange(e) {
      console.log(e);
    },
    toEdit(item) {
      console.log(item);
      this.userInfo = {
        name: item.name,
        user_name: item.user_name,
        role_list: item.role_list,
      };
      this.user_id = item.user_id;
      this.visibleInfo = true;
    },
    deleteItem(item) {
      this.deleteIng = true;
      this.$post("userDel", { user_id: item.user_id })
        .then((res) => {
          this.deleteIng = false;
          let { code, msg, data } = res;
          if (code == 0) {
            this.$message.success(msg, 1.5);
            this.getList();
          } else {
            this.$message.error(msg, 1.5);
          }
        })
        .catch((err) => {
          this.deleteIng = false;
        });
    },
    initForm() {
      this.visibleInfo = false;
      this.userInfo = {
        name: "",
        user_name: "",
        password: "",
        role_list: [],
      };
      this.user_id = "";
    },
    handleCancel() {
      this.initForm();
    },
    initPassForm() {
      this.passInfo = {
        password: "",
        user_id: ''
      };
    },
    showEditPassword(item) {
      this.visiblePassword = true;
      this.passInfo.user_id = item.user_id
    },
    submitInfo() {
      let t = this;
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          let url = this.user_id ? "userEdit" : "userAdd";
          let obj = JSON.parse(JSON.stringify(this.userInfo));
          console.log(123123123123123123123);

          if (this.user_id) {
            obj.user_id = this.user_id;
            delete obj.password;
          }
          console.log("tijiao", obj);
          this.$post(url, obj)
            .then((res) => {
              let { code, msg, data } = res;
              this.confirmLoading = false;
              if (code == 0) {
                this.$message.success(msg, 1.5);
                this.initForm();
                this.getList();
              } else {
                this.$message.error(msg, 1.5);
              }
            })
            .catch((err) => {
              this.confirmLoading = false;
            });
        }
      });
    },
    cancelPassword() {
      this.visiblePassword = false;
     this.initPassForm()
    },
    submitPassword() {
      this.$refs.passForm.validate((valid) => {
        if (valid) {
          this.confirmPassLoading = true;
          let arr = [];
          let url = "upUserPassword";
          this.$post(url, this.passInfo)
            .then((res) => {
              let { code, msg, data } = res;
              this.confirmLoading = false;
              if (code == 0) {
                this.$message.success(msg, 1.5);
                this.visiblePassword = false;
                this.initPassForm()
              } else {
                this.$message.error(msg, 1.5);
              }
            })
            .catch((err) => {
              this.confirmLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less">
.add-btn-box {
  margin-bottom: 20px;
}
.userInfo {
  .ant-modal {
    width: 1000px !important;
  }
  .top-box {
    label {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .li {
    line-height: 30px;
    padding: 0 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
    .item-all {
      font-weight: bold;
      label {
        font-size: 16px;
      }
    }
  }
}
</style>
